// import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'
import { t } from '@/libs_sz/locale'
const getFormatter = (data, value) => {
  const getValue =
    myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
const getValue = (value) =>
  value ? String(value) : String(value) === '0' ? '0' : ''

// 获取 更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 理货单号
    {
      name: 'receiptNo',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.tallyNo')
    },
    // // 单据类型
    // {
    //   name: 'receiptType',
    //   span: 12,
    //   component: 'elSelect',
    //   adaptionw: true,
    //   showWordLimit: false,
    //   label: that.$t('lang.gles.orderManage.receiptType'),
    //   filterable: true,
    //   data: that.receiptTypeList || []
    // },
    // // 创建人
    // {
    //   name: 'createUser',
    //   span: 12,
    //   component: 'elInput',
    //   adaptionw: true,
    //   showWordLimit: false,
    //   label: that.$t('lang.gles.common.createUser')
    // },
    // 状态
    {
      name: 'receiptStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.status'),
      filterable: true,
      data: that.tallyReceiptStatusList || []
    },
    // 来源库区编码
    {
      name: 'fromWarehouseAreaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.fromWarehouseAreaId'),
      filterable: true,
      data: that.warehouseAreaList || []
    },
    // 来源库位编码
    {
      name: 'fromFactoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.orderManage.fromFactoryPositionId'),
      filterable: true
      // data: that.factoryPositionList || []
    },
    // 来源货位编码
    {
      name: 'fromGoodsPositionId',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'goodsPositionList',
      label: that.$t('lang.gles.orderManage.fromGoodsPositionId'),
      filterable: true
      // data: that.goodsPositionList || []
    },
    // 目标库区编码
    {
      name: 'targetWarehouseAreaId',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'goodsPositionList',
      label: that.$t('lang.gles.orderManage.targetWarehouseAreaId'),
      filterable: true,
      data: that.warehouseAreaList || []
    },
    // 目标库位编码
    {
      name: 'targetFactoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.orderManage.targetFactoryPositionId'),
      filterable: true,
      data: that.factoryPositionList || []
    },
    // 目标货位编码
    {
      name: 'targetGoodsPositionId',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'goodsPositionList',
      label: that.$t('lang.gles.orderManage.targetGoodsPositionId'),
      filterable: true
      // data: that.goodsPositionList || []
    },
    // 容器类型
    {
      name: 'containerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.containerTypePattern'),
      filterable: true,
      data: that.containerTypeList || []
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      filterable: true,
      data: that.turnoverContainerTypeList || []
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.materialId'),
      filterable: true,
      data: that.materialCodeList || []
    },
    // 创建时间
    {
      name: 'createTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.createTime')
    },
    // 编辑时间
    // {
    //   name: 'updateTime',
    //   span: 12,
    //   component: 'elDatePicker',
    //   type: 'datetimerange',
    //   'range-separator': '-',
    //   valueFormat: 'yyyy-MM-dd HH:mm:ss',
    //   label: that.$t('lang.gles.common.updateTime')
    // },
    // 完成时间
    {
      name: 'completedTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.completedTime')
    }
  ]
  return moreQueryData
}

// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 理货单号
    {
      prop: 'tallyNo',
      isShow: true,
      label: that.$t('lang.gles.orderManage.tallyNo'),
      minWidth: 190,
      showOverflowTooltip: true,
      slotName: 'clickSlot'
    },
    // 单据类型
    {
      prop: 'receiptType',
      isShow: true,
      label: that.$t('lang.gles.orderManage.receiptType'),
      width: 90,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.receiptTypeList, row[column])
      }
    },
    // 状态
    {
      prop: 'receiptStatus',
      isShow: true,
      label: that.$t('lang.gles.base.status'),
      width: 80,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.tallyReceiptStatusList, row[column])
      }
    },
    // 异常描述
    {
      prop: 'exceptionDesc',
      isShow: true,
      label: that.$t('lang.gles.orderManage.exceptionDesc'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return that.$t(row[column])
      }
    },
    // 来源库区编码
    {
      prop: 'fromWarehouseAreaCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromWarehouseAreaId'),
      minWidth: 120,
      showOverflowTooltip: true
    },
    // 来源库位编码
    {
      prop: 'fromFactoryPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromFactoryPositionId'),
      minWidth: 120,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.factoryPositionList, row[column])
      // }
    },
    // 来源货位编码
    {
      prop: 'fromGoodsPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromGoodsPositionId'),
      minWidth: 120,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.goodsPositionList, row[column])
      // }
    },
    // 目标库区编码
    {
      prop: 'targetWarehouseAreaCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetWarehouseAreaId'),
      minWidth: 120,
      showOverflowTooltip: true
      // formatter(row, column) {
      //   return getFormatter(that.warehouseAreaList, row[column])
      // }
    },
    // 目标库位编码
    {
      prop: 'targetFactoryPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetFactoryPositionId'),
      width: 110
    },
    // 目标货位编码
    {
      prop: 'targetGoodsPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetGoodsPositionId'),
      minWidth: 120,
      showOverflowTooltip: true
    },
    // 容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerTypePattern'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 容器数量
    {
      prop: 'containerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerSum')
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 周转容器数量
    {
      prop: 'turnoverContainerSum',
      isShow: true,
      width: 110,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum')
    },
    // 物料编码
    {
      prop: 'materialCode',
      isShow: true,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId')
    },
    // 物料名称
    {
      prop: 'materialName',
      isShow: true,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(that.materialNameList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 备注
    {
      prop: 'remarks',
      isShow: true,
      label: that.$t('lang.gles.orderManage.remark'),
      width: 100,
      showOverflowTooltip: true
    },
    // 创建人
    {
      prop: 'createUser',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.createUser')
    },
    // 创建时间
    {
      prop: 'createTime',
      isShow: true,
      label: that.$t('lang.gles.common.createTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 完成时间
    {
      prop: 'completedTime',
      isShow: true,
      label: that.$t('lang.gles.common.completedTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    }
  ]
  return searchTableItem
}

// 基础信息
export const getEditBaseFormData = (that, row, options) => [
  // 理货单号
  {
    name: 'tallyNo',
    value: row.tallyNo || '',
    span: 8,
    component: 'elInput',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.tallyNo')
  },
  // 单据类型
  {
    name: 'receiptType',
    value: Number(getValue(row.receiptType)),
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.receiptType'),
    filterable: true,
    data: options.receiptTypeList || []
  },
  // 备注
  {
    name: 'remarks',
    value: row.remarks || '',
    span: 8,
    component: 'elInput',
    adaptionw: true,
    disabled: true,
    label: that.$t('lang.gles.base.dictRemark')
  }
]

// 容器/物料详情列表(有容器)
export const getDetailTableItems = (that, options) => {
  const searchTableItem = [
    // 移动容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.common.moveContainerType'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'containerId',
      isShow: true,
      label: that.$t('lang.gles.common.moveContainerCode'),
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 移动容器数量
    {
      prop: 'containerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.moveContainerSum')
    },
    // 来源库区编码
    {
      prop: 'fromWarehouseAreaId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromWarehouseAreaId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 来源库位编码
    {
      prop: 'fromFactoryPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromFactoryPositionId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.factoryPositionList, row[column])
      }
    },
    // 来源货位编码
    {
      prop: 'fromGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromGoodsPositionId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 目标库区编码
    {
      prop: 'targetWarehouseAreaId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetWarehouseAreaId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 目标库位编码
    {
      prop: 'targetFactoryPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetFactoryPositionId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.factoryPositionList, row[column])
      }
    },
    // 目标货位编码
    {
      prop: 'targetGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetGoodsPositionId'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    }
  ]
  return searchTableItem
}

// 容器/物料详情子列表(有容器)
export const getDetailChildTableItems = (that, options) => {
  const searchTableItem = [
    // 移动货位编码
    {
      prop: 'goodsPositionId',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.moveGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerId',
      isShow: true,
      width: 110,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 周转容器数量
    {
      prop: 'turnoverContainerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum'),
      width: 110
    },
    // 物料一级分类
    {
      prop: 'materialClassifyFirstName',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialFirstType'),
      width: 110
    },
    // 物料二级分类
    {
      prop: 'materialClassifySecondName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialSecondType')
    },
    // 物料编码
    {
      prop: 'materialId',
      isShow: true,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialName',
      isShow: true,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialNameList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 基本单位
    {
      prop: 'materialUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 计量数量
    {
      prop: 'materialCountSum',
      isShow: true,
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'materialCountUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialDirectionName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialToward')
    },
    // 等级状态
    {
      prop: 'materialGrade',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperties',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    }
  ]
  return searchTableItem
}

// 容器/物料详情列表(无容器)
export const getDetailNoContainerTableItems = (that, options) => {
  const searchTableItem = [
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 周转容器数量
    {
      prop: 'turnoverContainerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum'),
      showOverflowTooltip: true,
      width: 110
    },
    // 物料一级分类
    {
      prop: 'materialClassifyFirstName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialFirstType'),
      width: 110,
      showOverflowTooltip: true
    },
    // 物料二级分类
    {
      prop: 'materialClassifySecondName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSecondType'),
      width: 110,
      showOverflowTooltip: true
    },
    // 物料编码
    {
      prop: 'materialId',
      isShow: true,
      width: 100,
      label: that.$t('lang.gles.orderManage.materialId'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialName',
      isShow: true,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialNameList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 基本单位
    {
      prop: 'materialUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 计量数量
    {
      prop: 'materialCountSum',
      isShow: true,
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'materialCountUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialDirectionName',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialToward')
    },
    // 等级状态
    {
      prop: 'materialGrade',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperties',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    },
    // 来源库区编码
    {
      prop: 'fromWarehouseAreaId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromWarehouseAreaId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 来源库位编码
    {
      prop: 'fromFactoryPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromFactoryPositionId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.factoryPositionList, row[column])
      }
    },
    // 来源货位编码
    {
      prop: 'fromGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.fromGoodsPositionId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 目标库区编码
    {
      prop: 'targetWarehouseAreaId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetWarehouseAreaId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 目标库位编码
    {
      prop: 'targetFactoryPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetFactoryPositionId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.factoryPositionList, row[column])
      }
    },
    // 目标货位编码
    {
      prop: 'targetGoodsPositionId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.targetGoodsPositionId'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    }
  ]
  return searchTableItem
}

// 获取任务详情table
export const getTaskTableItems = (that, options) => [
  // 任务ID
  {
    prop: 'taskCode',
    isShow: true,
    label: `${that.$t('lang.gles.orderManage.task')}ID`,
    width: 100,
    showOverflowTooltip: true
  },
  // 作业类型
  {
    prop: 'taskType',
    isShow: true,
    label: that.$t('lang.gles.orderManage.operatingType'),
    formatter(row, column) {
      return getFormatter(options.subTaskTypeList, row[column])
    }
  },
  // 目标库位/货位
  {
    prop: 'targetPositionCode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.targetPosition'),
    width: 115
  },
  // 作业方式
  {
    prop: 'workMode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.operatingMode'),
    formatter(row, column) {
      return getFormatter(options.operatingModeList, row[column])
    }
  },
  // 机器人任务ID
  {
    prop: 'robotTaskId',
    isShow: true,
    width: 110,
    label: `${that.$t('lang.gles.orderManage.robotTaskId')}ID`
  },
  // 机器人编号
  {
    prop: 'robotCode',
    isShow: true,
    width: 110,
    label: that.$t('lang.gles.orderManage.robotCode')
  },
  // 任务状态
  {
    prop: 'taskStatus',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.taskStatus'),
    formatter(row, column) {
      return getFormatter(options.taskStatusList, row[column])
    }
  },
  // 容器编码
  {
    prop: 'containerArchivesId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.containerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 货位编码
  {
    prop: 'goodsPositionId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.base.goodsPositionCode'),
    formatter(row, column) {
      return getFormatter(options.goodsPositionList, row[column])
    }
  },
  // 周转容器编码
  {
    prop: 'turnoverContainerArchivesId',
    isShow: true,
    width: 110,
    label: that.$t('lang.gles.orderManage.turnoverContainerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 周转容器数量
  {
    prop: 'turnoverContainerSum',
    isShow: true,
    width: 110,
    label: that.$t('lang.gles.orderManage.turnoverContainerSum')
  },
  // 物料名称
  {
    prop: 'materialName',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialName'),
    width: 100,
    showOverflowTooltip: true,
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料编码
  {
    prop: 'materialsId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.materialId'),
    formatter(row, column) {
      return getFormatter(options.materialCodeList, row[column])
    }
  },
  // 物料数量
  {
    prop: 'materialsSum',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 物料朝向
  {
    prop: 'materialDirectionName',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.materialToward')
  },
  // 批次
  {
    prop: 'materialBatchNo',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.batchNo')
  },
  // 等级状态
  {
    prop: 'materialGrade',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.levelStatus'),
    formatter(row, column) {
      return getFormatter(options.gradeStatusList, row[column])
    }
  },
  // 创建时间
  {
    prop: 'createTime',
    isShow: true,
    width: 160,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.common.createTime')
  },
  // 完成时间
  {
    prop: 'finishTime',
    isShow: true,
    width: 160,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.common.completedTime')
  }
]

// 获取任务详情子table
export const getTaskChildTableItems = (that, options) => [
  // 容器编码
  {
    prop: 'containerCode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.containerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 货位编码
  {
    prop: 'goodsPositionId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.deviceGoodsPositionId'),
    formatter(row, column) {
      return getFormatter(options.goodsPositionList, row[column])
    }
  },
  // 周转容器编码
  {
    prop: 'turnoverContainerId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 周转容器数量
  {
    prop: 'turnoverContainerSum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerSum')
  },
  // 物料名称
  {
    prop: 'materialName',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialName'),
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料编码
  {
    prop: 'materialId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialId'),
    formatter(row, column) {
      return getFormatter(options.materialCodeList, row[column])
    }
  },
  // 物料数量
  {
    prop: 'materialNum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 物料朝向
  {
    prop: 'materialToward',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialToward')
  },
  // 批次
  {
    prop: 'batchNo',
    isShow: true,
    label: that.$t('lang.gles.orderManage.batchNo')
  },
  // 等级状态
  {
    prop: 'levelStatus',
    isShow: true,
    label: that.$t('lang.gles.orderManage.levelStatus'),
    formatter(row, column) {
      return getFormatter(options.gradeStatusList, row[column])
    }
  }
]

// 获取日志table
export const getLogTableItems = (that, options) => [
  // 状态
  {
    prop: 'taskStatus',
    label: that.$t('lang.gles.base.status'),
    formatter(row, column) {
      return getFormatter(options.tallyReceiptStatusList, row[column])
    }
  },
  // 时间
  {
    prop: 'createTime',
    label: that.$t('lang.gles.common.time')
  },
  // 操作人
  {
    prop: 'createUser',
    label: that.$t('lang.gles.common.handleName')
  }
]

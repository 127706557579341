<template>
  <div class="warehousing-task">
    <m-table
      border
      :table-item="tableItem"
      :table-data="tableData"
      :page-data="pageData"
    >
      <!-- 展开 -->
      <!-- <template v-slot:expand="scope">
        <div class="child-table">
          <m-table
            size="mini"
            :table-item="childTableItem"
            :table-data="scope.row.detailList"
            :page-data="pageData"
          />
        </div>
      </template> -->
    </m-table>
  </div>
</template>
<script>
import { getTaskTableItems, getTaskChildTableItems } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'info',
      currentCom: '',
      tableData: [],
      extendFormConfig: {
        isNeedBtn: false
      },
      pageData: null
    }
  },
  computed: {
    tableItem() {
      return getTaskTableItems(this, this.options)
    },
    childTableItem() {
      return getTaskChildTableItems(this)
    }
  },
  created() {
    this.listTaskDetail()
  },
  methods: {
    async listTaskDetail() {
      const params = {
        businessNo: this.rowDetail.tallyNo,
        receiptType: this.rowDetail.receiptType
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('listTaskDetail'),
        params
      )
      console.log(data)
      data && data.forEach(item => {
        item.materialName = item.materialsId
      })
      this.tableData = data || []
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'WarehousingOrderList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

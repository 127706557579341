<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :show-close="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-head">
        <div class="drawer-info">
          <span class="f-weight">{{ rowDetail.tallyNo }}</span>
          <p>
            <span class="f-weight">{{ $t('lang.gles.base.status') }}：</span>
            <span>{{ statusValue }}</span>
          </p>
          <p>
            <span class="f-weight">{{ $t('lang.gles.orderManage.exceptionDesc') }}：</span>
            <span>{{ rowDetail.exceptionDesc }}</span>
          </p>
        </div>
        <div class="drawer-operate-btn">
          <i class="el-icon-close" style="top: -6px" @click="$emit('update:visible', false)" />
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('理货单信息')" name="info" />
        <el-tab-pane :label="$t('理货任务')" name="task" />
        <el-tab-pane :label="$t('lang.gles.orderManage.logInfo')" name="log" />
      </el-tabs>
      <component
        :is="currentCom"
        :row-detail="rowDetail"
        :options="options"
      />
    </div>
  </el-drawer>
</template>
<script>
import TallyingInfo from './tallyingInfo.vue'
import TallyingTask from './tallyingTask.vue'
import LogInfo from './logInfo.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import myTransform from '@/libs_sz/utils/transform'
import addMixins from '@/mixins/addMixins'

export default {
  components: {
    MForm,
    TallyingInfo,
    TallyingTask,
    LogInfo
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'info',
      currentCom: 'TallyingInfo',
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      }
    }
  },
  computed: {
    statusValue() {
      const val = myTransform.arrToObject(this.options.tallyReceiptStatusList, 'value', 'label')[this.rowDetail.receiptStatus] || this.rowDetail.receiptStatus
      return val
    }
  },
  watch: {
    activeName(val) {
      switch (val) {
        case 'info':
          this.currentCom = 'TallyingInfo'
          break
        case 'task':
          this.currentCom = 'TallyingTask'
          break
        case 'log':
          this.currentCom = 'LogInfo'
          break
      }
    }
  },
  created() {
  },
  methods: {
    handleClick(tab, event) {
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
  padding: 0 15px;
}
.drawer-wrapper {
  // position: relative;
  padding: 0;
}
.f-weight {
  font-weight: 600;
}
.drawer-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 10px;
}
.drawer-info {
  display: flex;

  p {
    padding-left: 30px;
  }
}
</style>
